import { uid } from './utils/uid.js';
import { customFonts } from './custom-fonts.js';
import { MAX_SHADOW_BLUR_SIZE, MAX_STROKE_WIDTH, MAX_ROTATE } from './constants.js';

const defaultTextboxData = {
  id: '',
  text: '',
  fillColor: '#ffffff',
  strokeColor: '#000000',
  font: 'Pressuru',
  fontSize: 40,
  fontWeight: 'normal',
  textAlign: 'center',
  shadowBlur: 0,
  strokeWidth: 1.5,
  offsetY: 0,
  offsetX: 0,
  rotate: 0,
  allCaps: true
};

const textboxes = new Map();

class Textbox {
  constructor(data) {
    const id = uid('textbox', Date.now().toString(36));

    this.data = data ? { ...data, id } : { ...defaultTextboxData, id };

    textboxes.set(id, this);

    document.dispatchEvent(
      new CustomEvent(`textbox-create`, {
        bubbles: true,
        composed: true,
        detail: { textbox: this }
      })
    );
  }

  getData() {
    return this.data;
  }

  static create(data) {
    return new Textbox(data);
  }

  static getAll() {
    return textboxes;
  }

  static getById(id) {
    return textboxes.get(id);
  }

  static remove(id) {
    textboxes.delete(id);

    document.dispatchEvent(
      new CustomEvent(`textbox-remove`, {
        bubbles: true,
        composed: true,
        detail: { id }
      })
    );
  }

  static createElement(textbox, autoFocus = true) {
    if (!(textbox instanceof Textbox)) {
      return;
    }

    const data = textbox.getData();
    const {
      id,
      text,
      fillColor,
      strokeColor,
      fontSize,
      shadowBlur,
      strokeWidth,
      offsetX,
      offsetY,
      rotate
    } = data;

    const template = /* html */ `
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center gap-1">
        <button type="button" class="btn btn-link p-1 d-flex align-items-center justify-content-center" data-button="duplicate-text-box" title="Duplicate text box">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" pointer-events="none">
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
          </svg>
        </button>
        <button type="button" class="btn btn-link p-1 d-flex align-items-center justify-content-center" data-button="delete-text-box" title="Remove text box">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" pointer-events="none">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </button>
      </div>

      <textarea class="form-control meme-text px-2" type="text" data-input="text" autocomplete="off" rows="1" placeholder="${`Text #${textboxes.size}`}">${text}</textarea>

      <div class="d-flex align-items-center pe-2 gap-2">
        <div class="d-flex gap-1">
          <label for="fillColorInput" class="visually-hidden">Fill color</label>
          <input class="form-control form-control-color p-0 border-0 rounded-1 d-flex align-items-center justify-content-center" type="color" value="${fillColor}" id="fillColorInput" data-input="fillColor" title="Fill color" style="width: 24px; height: 24px;">

          <label for="strokeColorInput" class="visually-hidden">Outline color</label>
          <input class="form-control form-control-color p-0 border-0 rounded-1 d-flex align-items-center justify-content-center" type="color" value="${strokeColor}" id="strokeColorInput" data-input="strokeColor" title="Outline color" style="width: 24px; height: 24px;">
        </div>

        <button type="button" class="btn btn-light border-0 rounded-1 settings-button d-flex align-items-center justify-content-center" data-button="settings" title="Settings">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" pointer-events="none">
            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="p-2" data-section="settings" hidden>
      <div class="row g-2">
        <div class="col-12">
          <details class="emoji-picker-details">
            <summary>Emoji picker</summary>
            <emoji-picker class="light"></emoji-picker>
          </details>
        </div>

        <div class="col-4 mb-3">
          <label for="fontInput_${id}" class="mb-1 d-block text-truncate">Font: </label>

          <select class="form-select" data-input="font" id="fontInput_${id}">
            <optgroup label="Web fonts">
              <option value="Impact">Impact</option>
              <option value="Arial">Arial</option>
              <option value="Arial Black">Arial Black</option>
              <option value="Helvetica">Helvetica</option>
              <option value="Comic Sans MS">Comic Sans MS</option>
              <option value="Times">Times</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Courier New">Courier New</option>
              <option value="Verdana">Verdana</option>
              <option value="Georgia">Georgia</option>
              <option value="Palatino">Palatino</option>
              <option value="Garamond">Garamond</option>
              <option value="Bookman">Bookman</option>
              <option value="Trebuchet MS">Trebuchet MS</option>
            </optgroup>
            <optgroup label="Google fonts">
              ${customFonts.map(({ name, label }) => `<option value="${name}">${label}</option>`)}
            </optgroup>
          </select>
        </div>

        <div class="col-4 mb-3">
          <label for="fontSizeInput_${id}" class="mb-1 d-block text-truncate">Size:</label>
          <input class="form-control" type="number" min="1" value="${fontSize}" data-input="fontSize" id="fontSizeInput_${id}">
        </div>

        <div class="col-4 mb-3">
          <label for="fontWeightInput_${id}" class="mb-1 d-block text-truncate">Weight:</label>
          <select class="form-select" data-input="fontWeight" id="fontWeightInput_${id}">
            <option value="normal">Normal</option>
            <option value="bold">Bold</option>
          </select>
        </div>
      </div>

      <div class="row g-2">
        <div class="col-4 mb-3">
          <label for="shadowWidthInput_${id}" class="mb-1 d-block text-truncate">Shadow size:</label>
          <input class="form-control" type="number" min="0" max="${MAX_SHADOW_BLUR_SIZE}" value="${shadowBlur}" data-input="shadowBlur" id="shadowWidthInput_${id}">
        </div>

        <div class="col-4 mb-3">
          <label class="mb-1 d-block text-truncate" for="strokeWidthInput_${id}">Outline size:</label>
          <input class="form-control" type="number" min="0" max="${MAX_STROKE_WIDTH}" step="0.5" value="${strokeWidth}" data-input="strokeWidth" id="strokeWidthInput_${id}">
        </div>

        <div class="col-4 mb-3">
          <label for="textAlignInput_${id}" class="mb-1 d-block text-truncate">Text align:</label>
          <select class="form-select" data-input="textAlign" id="textAlignInput_${id}" value="right">
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </div>
      </div>

      <div class="row g-2">
        <div class="col-4 mb-3">
          <label class="mb-1 d-block text-truncate" for="offsetYInput_${id}">Vertical offset:</label>
          <input class="form-control" type="number" value="${offsetY}" data-input="offsetY" id="offsetYInput_${id}">
        </div>

        <div class="col-4 mb-3">
          <label class="mb-1 d-block text-truncate" for="offsetXInput_${id}">Horizontal offset:</label>
          <input class="form-control" type="number" value="${offsetX}" data-input="offsetX" id="offsetXInput_${id}">
        </div>

        <div class="col-4 mb-3">
          <label class="mb-1 d-block text-truncate" for="textRotateInput_${id}">Rotate:</label>
          <input class="form-control" type="number" value="${rotate}" data-input="rotate" id="textRotateInput_${id}" min="-360" max="${MAX_ROTATE}">
        </div>

        <div class="col-12">
          <div class="move-text-actions mb-3 d-flex align-items-center justify-content-center gap-2">
            <button type="button" class="btn btn-secondary d-flex align-items-center justify-content-center" data-action="move-text" aria-label="Up">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" pointer-events="none">
                <path d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
              </svg>
            </button>
            <button type="button" class="btn btn-secondary d-flex align-items-center justify-content-center" data-action="move-text" aria-label="Right">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" pointer-events="none">
                <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
              </svg>
            </button>
            <button type="button" class="btn btn-secondary d-flex align-items-center justify-content-center" data-action="move-text" aria-label="Down">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" pointer-events="none">
                <path d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
              </svg>
            </button>
            <button type="button" class="btn btn-secondary d-flex align-items-center justify-content-center" data-action="move-text" aria-label="Left">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" pointer-events="none">
                <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div class="row g-2">
        <div class="col-lg-12">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="allCapsCheckbox_${id}" data-input="allCaps">
            <label class="form-check-label" for="allCapsCheckbox_${id}">ALL CAPS</label>
          </div>
        </div>
      </div>
    </div>
  `;

    const fragment = document.createDocumentFragment();
    const div = document.createElement('div');

    div.setAttribute('id', id);
    div.setAttribute('data-section', 'textbox');
    div.className = 'bg-body-tertiary border shadow-sm mb-3 rounded';
    div.innerHTML = template;
    div.querySelectorAll('select').forEach(el => (el.value = data[el.dataset.input]));
    div
      .querySelectorAll('input[type="checkbox"]')
      .forEach(el => (el.checked = data[el.dataset.input]));

    const textboxEl = fragment.appendChild(div);

    if (autoFocus) {
      setTimeout(() => textboxEl.querySelector('[data-input="text"]').focus(), 0);
    }

    return textboxEl;
  }
}

export { Textbox };
